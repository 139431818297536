import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Service for handling translations in the application.
 *
 * The `TranslateService` manages loading and storing translation data for different languages.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  /**
   * Holds the translation data loaded from the JSON files.
   */
  data: any = {};

  /**
   * Creates an instance of TranslateService.
   *
   * @param http - An instance of HttpClient to perform HTTP requests.
   */
  constructor(private http: HttpClient) {}

  /**
   * Loads translation data for the specified language.
   *
   * Constructs the path to the translation JSON file based on the provided language code,
   * fetches the file using HTTP GET request, and stores the translation data.
   *
   * @param lang - The language code for which to load the translation (e.g., 'en', 'fr'). Defaults to 'en' if not provided.
   * @returns A promise that resolves with the translation data or an empty object in case of an error.
   */
  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        (translation) => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        () => {
          this.data = {};
          resolve(this.data);
        },
      );
    });
  }
}
