import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

/**
 * The DashboardWrapperComponent serves as the main wrapper for dashboard-related views.
 * It acts as a container component that handles routing and layout for dashboard pages. 
 * This component is standalone, meaning it can be used independently without needing a parent module.
 */
@Component({
  selector: 'app-dashboard-wrapper',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './dashboard-wrapper.component.html',
  styleUrl: './dashboard-wrapper.component.scss',
})
export class DashboardWrapperComponent { }
