import { AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import * as EventEmitter from "events";
import { CheckboxRadioCardData } from '../../interfaces/common.interface';

/**
 * Component for displaying a list of checkboxes and radio buttons organized in cards.
 * Allows selection of options with checkboxes and ensures only one radio button is selected at a time.
 */
@Component({
  selector: 'app-checkbox-radio-card',
  templateUrl: './checkbox-radio-card.component.html',
  styleUrls: ['./checkbox-radio-card.component.scss'],
})
export class CheckboxRadioCardComponent implements OnInit, AfterContentInit, OnChanges {
  /**
   * Input data for the checkboxes and radio buttons.
   * @type {Array<CheckboxRadioCardData>}
   */
  @Input() data!: Array<CheckboxRadioCardData>;
  /**
   * Form group for managing the checkboxes and radio buttons.
   */
  checkBoxForm!: FormGroup;

  /** Creates an instance of the class.
   * @param fb FormBuilder service for creating reactive forms.
   */
  constructor(private fb: FormBuilder) {}

  /**
   * Getter for the form array of checkboxes.
   * @returns {FormArray} The form array containing all checkbox controls.
   */
  get getDashboard(): FormArray {
    return this.checkBoxForm.get('dashboard') as FormArray;
  }

  /**
   * Getter for the form group of checkboxes.
   * @returns {FormGroup} The form group containing all checkbox controls.
   */
  get f() {
    return this.checkBoxForm.get('dashboard');
  }

  /**
   * Updates the form when `data` input changes.
   * @param changes SimpleChanges object containing changes to input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    for (const property in changes) {
      if (property === 'data' && changes[property].firstChange === false) {
        // console.log(changes[property]);
        this.data = changes[property].currentValue;
        // console.log(this.data, "chnGE BLOCK");
        this.addCheckboxesToForm();
      }
    }
    // this.data=
  }

  /**
   * Initializes the form group and logs the input data.
   */
  ngOnInit(): void {
    // console.log(this.data, "data in card box");

    this.checkBoxForm = this.fb.group({
      dashboard: this.fb.array([], [Validators.required]),
    });

    console.log(this.data, 'in child comp');
  }

  /**
   * Adds checkboxes to the form group based on the input data.
   * Creates form controls for each checkbox and radio button.
   */

  /**
   * Selects a specific checkbox and ensures only one radio button is selected at a time.
   * @param id The ID of the checkbox to be selected.
   */
  check(id) {
    // console.log(id);
    // console.log(this.getDashboard);
    this.getDashboard.controls.forEach((element: any) => {
      if (element.controls['id'].value == id) {
        element.controls['radio'].setValue(true);
      } else {
        element.controls['radio'].setValue(false);
      }
    });
    // console.log(this.getDashboard, "final");
  }

  /**
   * Creates a form group for a checkbox with associated properties.
   * @param object The data object containing properties for the checkbox.
   * @returns {FormGroup} The form group with checkbox controls.
   */
  getFormGroupForCheckBox(object): FormGroup {
    // console.log(object, "this is obje");

    return this.fb.group({
      checkbox: new FormControl(false),
      id: new FormControl(object.id),
      name: new FormControl(object.dashboardTitle),

      radio: new FormControl(false),
    });
  }

  /**
   * Returns the current value of the form group.
   * @returns {any} The value of the form group containing checkbox and radio button states.
   */
  sendResponse() {
    return this.checkBoxForm.value;
  }

  /**
   * Adds checkboxes to the form after the content has been initialized.
   */
  ngAfterContentInit() {
    this.addCheckboxesToForm();
  }

  private addCheckboxesToForm() {
    this.getDashboard.controls = [];

    this.data.forEach((ele) => {
      for (const dash of ele.entity) {
        this.getDashboard.push(this.getFormGroupForCheckBox(dash));
      }
    });
    // console.log(this.getDashboard.controls);
  }
}
