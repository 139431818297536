import { Component } from '@angular/core';
/**
 * A simple spinner component that displays a loading indicator. It is used to show a loading state while data is being fetched or processed.
 */
@Component({
  selector: 'app-spinner',
  standalone: true,
  imports:[],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
}
