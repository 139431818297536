import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

/**
 * Displays a message or a user-friendly interface when the requested page is not found.
 */
@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports:[RouterLink],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {}
