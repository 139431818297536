import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../authentication.service';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { PipesModule } from 'src/app/shared/modules/pipes/pipes.module';
/**
 * Component for handling forgot password functionality.
 * Displays a form where users can request a password reset link.
 */
@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, PipesModule, RouterLink, FooterComponent],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  /**
   * Form group for forgot password form.
   */
  forgotPasswordForm!: FormGroup;

  /**
   * Flag to indicate if the form has been submitted.
   */
  submitted = false;

  /**
   * Constructs an instance of ForgotPasswordComponent.
   *
   * @param fb - FormBuilder service for creating and managing forms.
   * @param router - Router service for navigation.
   * @param authService - AuthenticationService for handling authentication requests.
   * @param toastrService - ToastrService for displaying notifications.
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
  ) {}
  /**
   * Gets the form controls of the forgot password form.
   *
   * @returns {any} The controls of the forgot password form.
   */
  get forgotPasswordFormControls() {
    return this.forgotPasswordForm['controls'];
  }

  /**
   * Initializes the component by setting up the forgot password form.
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      userName: ['', Validators.compose([Validators.required])],
    });
  }

  /**
   * Submits the forgot password form.
   * If the form is valid, it calls the forgotPassword method of AuthenticationService to request a password reset.
   * Displays a success message if the request is successful and navigates to the login page.
   * @memberof ForgotPasswordComponent
   */
  submit() {
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
        (response) => {
          if (response) {
            this.toastrService.success('Please check your email to reset your password.');
            this.router.navigate(['auth/login']);
          }
        },
        (error) => {
          if (error) {
            //console.log(error);
          }
        },
      );
    }
  }
}
