<!-- header start -->
<header class="header-wrapper">
  <div class="header-left">
    @if (showMenuIcon()) {
      <div class="icon-buttons">
        <button
          class="btn btn-trigger"
          type="button"
          (click)="openNavMenuDroDownDialog()"
          [class]="{ 'close-menu': menu }"
        >
          <i-feather name="menu" class="feather-20"></i-feather>
          <i-feather name="x" class="feather-20"></i-feather>
        </button>
      </div>
    }
    <!-- <div class="header-logo pointer" routerLink="/clients"> -->
    <div class="header-logo">
      <img src="assets/images/logo/medocity_logo_white.svg" alt="Medocity" />
    </div>
  </div>
  <div class="header-right">
    <!-- <div class="hr-col">
      <div class="input-group input-search">
      <div class="input-group-prepend">
        <div class="input-group-text"><i-feather name="search"></i-feather></div>
      </div>
      <input type="text" class="form-control" placeholder="Search" />
    </div>
      <form>
        <div class="form-group search-input">
          <input type="text" class="form-control" placeholder="Search"/>
          <i-feather name="search" class="feather-20"></i-feather>
        </div>
      </form>
    </div> -->
    <div class="login-user hr-col">
      <img
        class="user-image rounded-circle profile-image"
        alt="Provisioning super user"
        [src]="userDetails?.profilePicture ? userDetails.profilePicture : ''"
        alt="Medocity"
        onErrorImg
      />
      <div class="user-group p-2 ms-2">
        @if (userDetails?.name) {
          <span class="user-name">{{ userDetails?.name }}</span>
        }
        <i-feather name="chevron-down" class="feather-20"></i-feather>

        <div class="user-group-dropdown">
          <a href="javascript:void(0)" class="text-danger p-2" (click)="signOut()">
            <i-feather name="log-out" class="feather-20 me-2"></i-feather> Sign Out</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->
