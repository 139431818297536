<mat-dialog-content>
  @if (!clientId) {
    <div>
      <ul class="main-navigation mb-0">
        @for (menu of menuItems; track $index) {
          <ng-container>
            @if (menu?.type?.toLowerCase() === 'menu') {
              <ng-container>
                <li
                  routerLinkActive="nav-active"
                  [routerLink]="menu.routerLink"
                  [class]="menu.isSeparator ? 'menu-separator' : ''"
                >
                  <i-feather [name]="menu.icon" class="feather-16 {{ menu.icon }}"></i-feather>
                  <span>{{ menu.label }}</span>
                </li>
              </ng-container>
            }
            @if (menu?.type?.toLowerCase() === 'separator') {
              <ng-container>
                <li class="menu-label" routerLinkActive="nav-active">
                  <span>{{ menu.label }}</span>
                </li>
              </ng-container>
            }
            @if (menu?.type?.toLowerCase() === 'submenu') {
              <ng-container>
                @for (subMenu of menu.items; track $index) {
                  <li
                    routerLinkActive="nav-active"
                    [class]="subMenu.isSeparator ? 'menu-separator' : ''"
                    [routerLink]="subMenu.routerLink"
                  >
                    <i-feather [name]="subMenu.icon" class="feather-16 {{ subMenu.icon }} "></i-feather>
                    <span>{{ subMenu.label }}</span>
                  </li>
                }
              </ng-container>
            }
          </ng-container>
        }
      </ul>
    </div>
  }
  <!-- start Menu based on client specific -->
  @if (clientId) {
    <div>
      <ul class="main-navigation mb-0">
        @for (menu of menuItems; track $index) {
          <ng-container>
            @if (menu?.type?.toLowerCase() === 'home') {
              <ng-container>
                <li class="menu-separator dashboard-menu-item">
                  <div class="client-dropdown-menu">
                    <div class="pointer" id="client-dropdown-menu" [matMenuTriggerFor]="clientDetailsMatMenu">
                      @if (basicClientDetails()?.imageUrl) {
                        <div class="client-menu-logo">
                          <img alt="image" [src]="basicClientDetails()?.imageUrl" alt="image" onErrorImg />
                        </div>
                      }
                      <span>{{ basicClientDetails()?.name }}</span>
                      <i-feather name="chevron-down" class="feather-16"></i-feather>
                    </div>
                    <mat-menu #clientDetailsMatMenu="matMenu" [class]="'view-all-client'">
                      <a
                        mat-menu-item
                        [routerLink]="'/selected-client/details/client-details'"
                        [queryParams]="{ clientId: clientId }"
                        >{{ basicClientDetails()?.name }}</a
                      >
                      <a mat-menu-item [routerLink]="menu.routerLink">View all clients</a>
                    </mat-menu>
                  </div>
                </li>
              </ng-container>
            }
            @if (menu?.type?.toLowerCase() === 'separator') {
              <ng-container>
                <li class="menu-label" routerLinkActive="nav-active">
                  <span>{{ menu.label }}</span>
                </li>
              </ng-container>
            }
            @if (menu?.type?.toLowerCase() === 'menu') {
              <ng-container>
                <li
                  routerLinkActive="nav-active"
                  [routerLink]="menu.routerLink"
                  [class]="menu.isSeparator ? 'menu-separator' : ''"
                  [queryParams]="{ clientId: clientId, moduleTitle: menu.label }"
                >
                  <i-feather [name]="menu.icon" class="feather-16 {{ menu.icon }}"></i-feather>
                  <span>{{ menu.label }}</span>
                </li>
              </ng-container>
            }
            @if (menu?.type?.toLowerCase() === 'submenu') {
              <ng-container>
                @for (subMenu of menu.items; track $index) {
                  <li
                    routerLinkActive="nav-active"
                    [class]="subMenu.isSeparator ? 'menu-separator' : ''"
                    [routerLink]="subMenu.routerLink"
                    [queryParams]="{ clientId: clientId, moduleTitle: subMenu.label }"
                  >
                    <i-feather [name]="subMenu.icon" class="feather-16 {{ subMenu.icon }} "></i-feather>
                    <span>{{ subMenu.label }}</span>
                  </li>
                }
              </ng-container>
            }
          </ng-container>
        }
      </ul>
    </div>
  }
</mat-dialog-content>
