import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { PlatformRole } from './entities/authentication';
/**
 * Service for handling authentication-related operations such as login, logout, and token management.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * Default language key used for setting the application language.
   */
  defaultLanguageKey = 'en';
  /**
   * Interval ID for the refresh token timer.
   * This ID is used to clear the interval when necessary.
   */
  refreshTokenTimerInterval!: ReturnType<typeof setInterval>;
  /**
   * Constructs an instance of AuthenticationService.
   *
   * @param authUrl - Base URL for authentication API.
   * @param apiUrl - Base URL for general API.
   * @param apiVersion - API version to use.
   * @param httpService - Service for making HTTP requests.
   * @param router - Router service for navigation.
   */
  constructor(
    @Inject('AUTH_API_BASE') private authUrl: string,
    @Inject('API_BASE') private apiUrl: string,
    @Inject('API_VERSION') private apiVersion,
    private httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * Logs in a user.
   *
   * @param body - The login credentials.
   * @returns Observable with the login response.
   */
  login(body): Observable<unknown> {
    const url = `${this.authUrl}/auth/platform-login`;
    //const url = `${this.authUrl}/auth/application-login`;
    return this.httpService.post(url, body);
  }

  /**
   * Initiates a password reset process.
   *
   * @param body - The user information for password reset.
   * @returns Observable with the response of the password reset request.
   */
  forgotPassword(body): Observable<unknown> {
    const url = `${this.apiUrl}/${this.apiVersion}/auth/forgotPassword`;
    return this.httpService.post(url, body);
  }

  /**
   * Resets the user's password.
   *
   * @param body - The new password information.
   * @returns Observable with the response of the password reset request.
   */
  resetPassword(body): Observable<unknown> {
    const url = `${this.apiUrl}/${this.apiVersion}/auth/resetPassword`;
    return this.httpService.patch(url, body);
  }
  /**
   * Sends a request to update the user's password.
   * @param {any} body - The request body containing necessary data.
   * @returns {Observable<any>} Observable containing the API response.
   */
  updatePassword(body): Observable<unknown> {
    const url = `${this.apiUrl}/${this.apiVersion}/auth/updatePassword`;
    return this.httpService.patch(url, body);
  }
  /**
   * Refreshes the authentication token.
   *
   * @param body - The refresh token request body.
   * @param headers - Optional HTTP headers.
   * @returns Observable with the response of the refresh token request.
   */
  refreshToken(body, headers) {
    const url = `${this.authUrl}/auth/token`;
    return this.httpService.post(url, body, headers);
  }

  /**
   * Switches the client by ID.
   *
   * @param clientId - The client ID to switch to.
   * @returns Observable with the response of the client switch request.
   */
  switchClientById(clientId: string) {
    const url = `${this.authUrl}/auth/request-client-token/${clientId}`;
    const headers = new HttpHeaders({
      'client-token': this.getClientToken() || '',
    });
    return this.httpService.post(url, {}, { headers: headers });
  }

  /**
   * Logs out the user.
   *
   * @returns Observable with the response of the logout request.
   */
  logout() {
    const url = `${this.authUrl}/auth/logout`;
    return this.httpService.get(url);
  }

  /**
   * Sets authentication tokens in local storage.
   *
   * @param tokens - The tokens to be set.
   */
  setTokens(tokens) {
    if (tokens) {
      this.removeTokens();
      localStorage.setItem('access_token', tokens.access_token ? tokens.access_token : '');
      localStorage.setItem('refresh_token', tokens.refresh_token ? tokens.refresh_token : '');
      localStorage.setItem('clientToken', tokens.clientToken ? tokens.clientToken : '');
      localStorage.setItem(
        'access_token_expires_in_minutes',
        tokens.access_token_expires_in_minutes ? tokens.access_token_expires_in_minutes : '',
      );
      if (tokens?.principal) {
        this.setUserDetails(tokens.principal);
      }
      if (tokens?.role) {
        this.setRoleDetails(tokens.role);
      }
      this.startRefreshTokenTimer();
    }
  }

  /**
   * Sets user details in local storage.
   *
   * @param user - The user details to be set.
   */
  setUserDetails(user) {
    localStorage.setItem('userDetails', user ? JSON.stringify(user) : '');
  }
  /**
   * Sets role details in local storage.
   *
   * @param role - The role details to be set.
   */
  setRoleDetails(role: PlatformRole) {
    if (role) {
      localStorage.setItem('role', role ? JSON.stringify(role) : '');
    }
  }
  /**
   * Gets user details from local storage.
   *
   * @returns The user details, or null if not set.
   */
  getUserDetails() {
    if (localStorage.getItem('userDetails')) {
      const userDetails = localStorage.getItem('userDetails');
      return userDetails ? JSON.parse(userDetails) : null;
    }
  }
  /**
   * Gets role details from local storage.
   *
   * @returns The role details, or null if not set.
   */
  getRoleDetails() {
    if (localStorage.getItem('role')) {
      const role = localStorage.getItem('role');
      return role ? JSON.parse(role) : null;
    }
  }
  /**
   * Sets the preferred language in local storage.
   *
   * @param lang - The language code, default is 'en'.
   */
  setLanguage(lang = 'en') {
    localStorage.setItem('NG_TRANSLATE_LANG_KEY', lang);
  }
  /**
   * Sets the access state flag in the local storage.
   *
   * The flag(`'accessAfterLoggedIn'`) indicates whether access should be granted or not after a user logs in.
   * The flag value is stored as a boolean (true or false).
   *
   * @param {boolean} flag - The value to set for the `'accessAfterLoggedIn'` key in local storage.
   *                          Default is `true`.
   */
  setAccessAfterLoggedIn(flag = true) {
    localStorage.setItem('accessAfterLoggedIn', JSON.stringify(flag));
  }
  /**
   * Gets the preferred language from local storage.
   *
   * @returns The language code, default is 'en'.
   */
  getLanguageKey() {
    return localStorage.getItem('NG_TRANSLATE_LANG_KEY') || this.defaultLanguageKey;
  }

  /**
   * Gets the access token from local storage.
   *
   * @returns The access token, or an empty string if not set.
   */
  geToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  /**
   * Retrieves the access state flag from local storage.
   *
   * This method checks the browser's local storage for the key `'accessAfterLoggedIn'`.
   *
   * @returns {boolean} - The access state flag:
   *                      - `true` if access is allowed after login (default if no value exists).
   *                      - `false` if access is explicitly denied after login.
   */
  getAccessAfterLoggedIn(): boolean {
    const accessAfterLoggedIn = localStorage.getItem('accessAfterLoggedIn');
    // If value exists and is valid, parse it. Otherwise, return true as default.
    return accessAfterLoggedIn ? JSON.parse(accessAfterLoggedIn) : true;
  }
  /**
   * Gets the client token from local storage.
   *
   * @returns The client token, or an empty string if not set.
   */
  getClientToken(): string {
    return localStorage.getItem('clientToken') || '';
  }
  /**
   * Removes all authentication tokens from local storage.
   */
  removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('clientToken');
    localStorage.removeItem('refresh_token_timer_in_seconds');
  }

  /**
   * Signs the user out by calling the logout API.
   * If the user is not authorized (i.e., does not have an OAuth access token),
   * it directly calls the logout callback.
   * @param {boolean} [refresh] - Optional parameter to indicate whether to refresh the page after logout.
   */
  signOut() {
    if (!this.isAuthorized()) {
      this.logoutCallback();
      return;
    }
    this.logout().subscribe({
      next: (res) => {
        if (res) {
          this.logoutCallback();
        }
      },
      error: (err) => {
        if (err) {
          this.logoutCallback();
        }
      },
    });
  }
  /**
   * Checks if the user is authorized by verifying the presence of an access token.
   *
   * @returns True if authorized, otherwise false.
   */
  isAuthorized() {
    return this.geToken() ? true : false;
  }
  /**
   * Logs out the user by clearing local storage and redirecting to login page.
   */
  logoutCallback() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  /**
   * Checks if the user is authorized by verifying the presence of an access token.
   *
   * @returns True if authorized, otherwise false.
   */
  superAdmin() {
    if (this.getRoleDetails()?.name?.toLowerCase() === 'platformadmin') {
      return true;
    }
    return false;
  }
  /**
   * Starts a timer to refresh the token before it expires.
   */
  startRefreshTokenTimer() {
    this.refreshTokenTimerInterval = setInterval(() => {
      if (this.isAuthorized()) {
        const refresh_token_timer_in_seconds = Number(localStorage.getItem('refresh_token_timer_in_seconds'));
        const access_token_expires_in_minutes = Number(localStorage.getItem('access_token_expires_in_minutes')) * 60;
        let timer =
          refresh_token_timer_in_seconds > 0 ? refresh_token_timer_in_seconds : access_token_expires_in_minutes;
        timer = timer - 1;
        localStorage.setItem('refresh_token_timer_in_seconds', String(timer));
        if (timer <= 60) {
          this.fetchRefreshToken();
          this.clearRefreshTokenTimerInterval();
        }
      }
    }, 1000);
  }

  /**
   * Clears the refresh token timer interval.
   */
  clearRefreshTokenTimerInterval() {
    if (this.refreshTokenTimerInterval) {
      clearInterval(this.refreshTokenTimerInterval);
    }
  }

  /**
   * Fetches a new refresh token and updates tokens in local storage.
   */
  fetchRefreshToken() {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.refreshToken(body, { headers: headers }).subscribe(
        (response) => {
          this.clearRefreshTokenTimerInterval();
          this.startRefreshTokenTimer();
          this.setTokens(response);
        },
        (error) => {
          if (error) {
            // this.toastr.error(error.error.message, error.error.error);
            //this.signOut();
          }
        },
      );
    }
  }
}
