import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ParamsOptions } from 'src/app/shared/interfaces/params.interface';
import { HttpService } from 'src/app/shared/services/http.service';
import {
  BasicClientDetails,
  Client,
  ClientLabel,
  Counter,
  ZendeskOrganization,
} from './entities/client';
/**
 * Service to manage and interact with client data.
 * Provides methods for fetching, adding, editing, and managing client details and counters.
 */
@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  /**
   * Internal storage for client details.
   */
  #client: Client | null = null;

  /**
   * Internal storage for total counters related to clients.
   */
  #totalCounters: Counter | null = null;

  /**
   * Observable to trigger refresh of client details.
   */
  refreshClientDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Signal to store basic client details.
   */
  basicClientDetails: WritableSignal<BasicClientDetails | null> = signal(null);

  /**
   * Computed signal to get the basic client details.
   */
  basicClientDetails$: Signal<BasicClientDetails | null> = computed(() => {
    return this.basicClientDetails();
  });

  /**
   * Constructs an instance of ClientsService.
   * @param apiUrl - Base URL for API endpoints.
   * @param apiVersion - Version of the API.
   * @param httpService - Service for making HTTP requests.
   * @param router - Router service for navigation.
   */
  constructor(
    @Inject('API_BASE') private apiUrl: string,
    @Inject('API_VERSION') private apiVersion,
    private httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * Sets the client label in local storage.
   *
   * @param clientLabel - The client label to store.
   */
  setClientId(id: string) {
    if (id) {
      localStorage.setItem('clientId', id);
    }
  }
  /**
   * Sets the client label in local storage.
   *
   * @param clientLabel - The client label to store.
   */
  setClientLabel(clientLabel: ClientLabel) {
    if (clientLabel) {
      localStorage.setItem('clientLabel', JSON.stringify(clientLabel));
    }
  }
  /**
   * Retrieves the client label from local storage.
   *
   * @returns {ClientLabel | null} The client label, or null if not found.
   */
  getClientLabel() {
    return JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('clientLabel'))));
  }
  /**
   * Retrieves the client Id from local storage.
   *
   * @returns {ClientID | null} The client Id, or null if not found.
   */
  getClientId(): string | null {
    return JSON.parse(JSON.stringify(localStorage.getItem('clientId'))) || null;
  }
  /**
   * Fetches a list of clients based on provided parameters.
   *
   * @param params - Query parameters for filtering, sorting, and searching.
   * @returns {Observable<any>} Observable of the HTTP response.
   */
  getClients(params) {
    const url = `${this.apiUrl}/${this.apiVersion}/client/system`;
    if (!params.filter) {
      delete params.filter;
    }
    if (!params.sort) {
      delete params.sort;
    }
    delete params.search;
    return this.httpService.get(url, params, false);
  }

  /**
   * Fetches values for a specific column in the client table.
   *
   * @param columnName - The name of the column to fetch values for.
   * @param params - Query parameters for filtering and sorting.
   * @returns {Observable<any>} Observable of the HTTP response.
   */
  getColumnValueOfClientTable(columnName: string, params: ParamsOptions) {
    const url = `${this.apiUrl}/${this.apiVersion}/client/column/${columnName}`;
    if (!params.filter) {
      delete params.filter;
    }
    if (!params.sort) {
      delete params.sort;
    }
    delete params.page;
    delete params.limit;
    if (!params.sort || params.search == '') {
      delete params.search;
    }
    return this.httpService.get(url, params, false);
  }

  /**
   * Retrieves a specific client by ID.
   *
   * @param clientId - The ID of the client to retrieve.
   * @returns {Observable<any>} Observable of the HTTP response.
   */
  getClientById(clientId: string) {
    const url = `${this.apiUrl}/${this.apiVersion}/client/${clientId}`;
    return this.httpService.get(url);
  }

  /**
   * Adds a new client.
   *
   * @param body - The client data to be added.
   * @param headers - Optional headers for the request.
   * @returns {Observable<any>} Observable of the HTTP response.
   */
  addClient(body: object, headers?) {
    const url = `${this.apiUrl}/${this.apiVersion}/client`;
    return this.httpService.post(url, body, headers);
  }

  /**
   * Edits an existing client.
   *
   * @param id - The ID of the client to edit.
   * @param body - The updated client data.
   * @param headers - Optional headers for the request.
   * @returns {Observable<any>} Observable of the HTTP response.
   */
  editClient(id: string, body: object, headers?) {
    const url = `${this.apiUrl}/${this.apiVersion}/client/${id}`;
    return this.httpService.patch(url, body, headers);
  }
  /**
   * Maps an organization from the support system into Zendesk by making a POST request.
   * The function sends the organization data to Zendesk's API to either create or update
   * an existing organization record.
   *
   * @param {SupportOrganization} body - The organization data to be mapped and sent to Zendesk.
   *    It should match the structure required by Zendesk's API for creating or updating organizations.
   *
   */
  public mapOrganizationIntoZendesk(body: ZendeskOrganization) {
    const url = `${this.apiUrl}/${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, { endPoint: '/organizations/create_or_update.json', method: 'POST', data: body });
  }
  /**
   * Sets the client details in internal storage.
   *
   * @param client - The client details to store.
   */
  setClientDetails(client) {
    this.#client = client || null;
  }

  /**
   * Sets the total counters related to clients.
   *
   * @param counter - The total counters to store.
   */
  setTotalCounters(counter: Counter | null) {
    this.#totalCounters = counter || null;
  }

  /**
   * Retrieves the client details from internal storage.
   *
   * @returns {Client | null} The client details, or null if not set.
   */
  getClientDetails() {
    return this.#client;
  }

  /**
   * Retrieves the total counters from internal storage.
   *
   * @returns {Counter | null} The total counters, or null if not set.
   */
  getTotalCounters() {
    return this.#totalCounters;
  }

  /**
   * Retrieves basic client details.
   *
   * @returns {BasicClientDetails | null} Basic details of the client, or null if not available.
   */
  getBasicClientDetails() {
    if (this.getClientDetails()) {
      return {
        id: this.getClientDetails()?.id || '',
        name: this.getClientDetails()?.name || '',
        imageUrl: this.getClientDetails()?.imageUrl || '',
        clientType: this.getClientDetails()?.clientType || '',
      };
    }
    return null;
  }

  /**
   * Redirects to the client details page.
   *
   * @param id - The ID of the client to navigate to.
   */
  redirectToClientDetails(id: string) {
    const url = id ? '/selected-client/details/' : this.router.url.split('?')[0];
    this.router.navigate([url], {
      queryParams: { clientId: id },
    });
  }

  /**
   * Fetches and sets client details by ID.
   *
   * @param clientId - The ID of the client to fetch.
   */
  getClientDetailsById(clientId: string) {
    if (this.getClientDetails()) {
      return;
    }
    this.getClientById(clientId).subscribe({
      next: (response) => {
        if (response) {
          if (response?.client && response?.count) {
            response.count.totalManagedUser = response.client.totalManagedUser;
            response.count.totalManagerUser = response.client.totalManagerUser;
            this.setClientId(response?.client?.id);
            this.setClientLabel(response?.client?.clientLabel);
            this.setClientDetails(response?.client || null);
            this.setTotalCounters(response.count || null);
            this.basicClientDetails.set(this.getBasicClientDetails());
            this.refreshClientDetails.next(true);
          }
        }
      },
    });
  }
  /**
   * Resets client-related details in the ClientsService.
   * Clears client details, total counters, and basic client details, and triggers a refresh.
   */
  resetClientDetails() {
    this.setClientDetails(null);
    this.setTotalCounters(null);
    this.basicClientDetails.set(null);
    this.refreshClientDetails.next(false);
  }
}
