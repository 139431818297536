// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * The environment configuration for the development mode.
 */
export const environment = {
  production: false,
  name: 'develop',
  webAPI: {
    authUrl: 'https://oauth2.medocity.net',
    apiUrl: 'https://api.medocity.net',
    version: 'v3',
    translateURL:
      'https://translation.googleapis.com/language/translate/v2?key=AIzaSyAUCDkVS9qsDs4zEHZu4xSOQo0sgkKOH6w',
    cerebellumURL: 'https://cerebellum.medocity.net',
  },
  appName: 'provisioning',
  //App specific settings
  appConfig: {
    name: 'provisioning',
    title: 'Medocity Configurator',
    type: 'provisioning',
    version: '3.11',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
