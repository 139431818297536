import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [],
  imports: [SharedModule, AuthenticationRoutingModule],
  providers: [{ provide: 'AUTH_API_BASE', useValue: environment.webAPI.authUrl }],
})
export class AuthenticationModule {}
